.member-options {
    position:absolute;
    top: 2.5rem;
    width: 14rem;
    background: $color-grey-100;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    z-index:2;
    max-height: 16.2rem;
    display: none;
    visibility: hidden;
    opacity: 0;
    flex-direction: column;

    &-scrollable {
        overflow-y:scroll;
    }

    &-visible {
        display:flex;
        visibility: visible;
        opacity: 1;
    }

    &::-webkit-scrollbar {
        width: .5rem;
        background-color: #ccc;
    }
    &::-webkit-scrollbar-thumb {
        width: .5rem;
        background: $color-grey-medium;
    }
}

