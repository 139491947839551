.openInDesktop-dropdown {
  position: relative;
  display: inline-block;
  margin-top: 2.4rem;
  margin-bottom: .2rem;

  .openInDesktop-dropbtn {
    display: flex;
    align-items: stretch;
    background-color: $color-orange-800;
    color: $color-grey-100;
    font-weight: 600;
    font-size: 1rem;
    height: 3.5rem;
    border: none;
    cursor: pointer;
    outline: none;

    .dropbtn-span {
      display: flex;
      align-items: center;
      font-size: 1.4rem;
      padding: 0 1.7rem;
    }

    .dropbtn-arrow {
      display: flex;
      align-items: center;
      padding: 0 1rem;
      border-left: 1px solid white;
    }

    &:hover {
      background-color: #fb923c;
    }
  }

  .openInDesktop-dropdown-content {
    display: block;
    position: absolute;
    right: 0;
    background-color: white;
    width: 100%;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    top: unset;
    button {
      color: black;
      padding: 0.6rem 1.6rem;
      text-decoration: none;
      display: flex;
      align-items: center;
      outline: none;
      border: none;
      background: transparent;
      width: 100%;
      cursor: pointer;
      text-align: left;

      svg {
        flex: 1;
        margin-right: 1rem;
      }

      span {
        flex: 5;
        font-size: 1.4rem;
        margin-right: 1rem;
        white-space: nowrap;
      }

      &:hover {
        background-color: #f1f1f1;
      }
    }
  }

  @media screen and (max-height: 1068px) and (min-width: 1800px) {
    .openInDesktop-dropdown-content {
      top: -8.8rem;
    }
  }
   
  @media screen and (max-height: 803px) and (max-width: 1800px) {
    .openInDesktop-dropdown-content {
      top: -8.8rem;
    }
  }
}
