.footer {
    position:fixed;
    bottom:0;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 2.8rem;
    background-color: $color-black;
    z-index:2;

    &-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0 2.5rem;
    }

    &-copyright {
        display: flex;
        align-items: center;
        color: $color-grey-100;
        font-size: 1.2rem;
    }

    &-links-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    

    &-link {
        color: $color-grey-100;
        font-size: 1.2rem;

        &:not(:last-child) {
            margin-right: 1rem;
        }
    }
}