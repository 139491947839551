.toasts-container {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 5;
}

.toast {
  background-color: #444444;
  color: $color-grey-100;
  margin-top: 1rem;
  padding: 1.5rem;
  border-radius: 0.5rem;
  transform: translateY(0);
  transition: 0.4s;

  &-show {
    transform: translateY(0);
  }

  &-error {
    background-color: rgb(220, 0, 0);
  }
}
