.button {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 4rem;
   padding: 0 5rem;
   color: $color-grey-100;
   font-size: 1.5rem;
   cursor: pointer;
   border: 0;
   text-decoration: none;

   &-text {
       color: $color-grey-100;
       text-decoration: none;
   }

   &-primary {
      background-color: $color-orange-800;
   }
   
   &-secondary {
      color: $color-grey-900;
      background-color: $color-grey-100;
      border: 1px solid $color-grey-500;

      &:hover {
         background-color: $color-grey-200;
      }
   }

   &-medium {
      height: 3rem;
      padding: 0 2rem;
      font-size: 1.4rem;
   }

   @include respond(tab-port) {
       width: 100%;
    }

    @include respond(phone) {
       width: 100%;
    }
    @include respond(small-phone) {
       width: 100%;
    }
}
