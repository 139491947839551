.total-items {
    position: absolute;
    top: 21rem;
    left: 13%;
    padding: 1rem 0;

    @include respond(small-phone) {
        left: 40%; 
    }

    @include respond(phone) {
        left: 33%;
    }

    @include respond(tab-port) {
        left: 23%;
    }

    @include respond(tab-land) {
        left: 18%;
    }

    @include respond(desktop) {
        left: 13%;
    }

    @include respond(big-desktop) {
        left: 13%;
    }
}

.loader-container {
    height: 100%;
    width: 84%;
    margin-left: 16%;
    height: calc(100vh - 23.8rem);

    &-detail {
        width: 59% !important;

        @include respond(tab-land) {
            width: 57% !important;
            margin-left: 18%;
        }
    
        @include respond(desktop) {
            width: 62% !important;
            margin-left: 13%;
        }
    
        @include respond(big-desktop) {
            width: 62% !important;
            margin-left: 13%;
        }
    }

    @include respond(small-phone) {
        width: 58%;
        margin-left: 42%; 
    }

    @include respond(phone) {
        width: 67%;
        margin-left: 33%;
    }
    @include respond(tab-port) {
        width: 77%;
        margin-left: 23%;
    }

    @include respond(tab-land) {
        width: 82%;
        margin-left: 18%;
    }

    @include respond(desktop) {
        width: 87%;
        margin-left: 13%;
    }

    @include respond(big-desktop) {
        width: 87%;
        margin-left: 13%;
    }
}

.con-design-items-page {
    padding-top: 15rem;

    &__message {
        width: 87%; 
        margin-left: 13%;
        position: relative;
        top: 2rem;
    }

    &__container {
        display: grid;
        grid-template-columns: repeat(7,1fr);
        gap: 1rem;
        justify-content: center;
        width: 84%;
        margin-left: 16%;
        padding: 3rem 1rem;
        margin-top: 3rem;

        &__detail {
            grid-template-columns: repeat(5,1fr);
            width: 59% !important;

            @include respond(small-phone) {
                width: 100%
            }
    
            @include respond(phone) {
                width: 100%
            }
            
            @include respond(tab-port) {
                width: 100%
            }

            @include respond(tab-land) {
                grid-template-columns: repeat(4,1fr)!important;
                width: 57% !important;
            }

            @include respond(desktop) {
                width: 62% !important;
            }
    
            @include respond(big-desktop) {
                grid-template-columns: repeat(6,1fr)!important;
            }

            
        }

        @include respond(small-phone) {
            grid-template-columns: 1fr;
            width: 58%;
            margin-left: 42%; 
        }

        @include respond(phone) {
            grid-template-columns: repeat(2,1fr);
            width: 67%;
            margin-left: 33%;
        }
        @include respond(tab-port) {
            grid-template-columns: repeat(3,1fr);
            width: 77%;
            margin-left: 23%;
        }

        @include respond(tab-land) {
            grid-template-columns: repeat(5,1fr);
            width: 82%;
            margin-left: 18%;
        }

        @include respond(desktop) {
            width: 87%;
            margin-left: 13%;
        }

        @include respond(big-desktop) {
            grid-template-columns: repeat(8,1fr);
            width: 87%;
            margin-left: 13%;
        }
    } 

    &__show-more {
        width: 84%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 4rem 0;
        padding-bottom: 5rem;
        margin-left: 16%;

        &__detail {
            margin-left: 16%;
            width: 59%;
    
            @include respond(tab-port) {
                width: 52% !important;
                margin-left: 23% !important;
            }
    
            @include respond(tab-land) {
                width: 57% !important;
                margin-left: 18% !important;
            }
    
            @include respond(desktop) {
                width: 62% !important;
                margin-left: 13% !important;
            }
        
            @include respond(big-desktop) {
                width: 62% !important;
                margin-left: 13% !important;
            }
        }

        @include respond(small-phone) {
            padding: 0 2rem;
            margin-bottom: 8rem;
            width: 58%;
            margin-left: 42%;
        }

        @include respond(phone) {
            padding: 0 2rem;
            margin-bottom: 8rem; 
            width: 67%;
            margin-left: 33%;
        }

        @include respond(tab-port) {
            padding: 0 2rem;
            margin-bottom: 8rem;
            width: 77%;
            margin-left: 23%;
        }

        @include respond(tab-land) {
            width: 82%;
            margin-left: 18%;
        }

        @include respond(desktop) {
            width: 87%;
            margin-left: 13%;
        }
    
        @include respond(big-desktop) {
            width: 87%;
            margin-left: 13%;
        }
    }
}
