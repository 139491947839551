.filters {
    position: fixed;
    top: 21rem;
    left: 0;
    height: 100%;
    width: 16%;
    padding: 1rem 2rem;

    &-group {
        display: flex;
        flex-direction: column;
        margin-bottom: 3rem;

        &-title {
            margin-bottom: 1rem;
        }
    }

    &-clear {
        display: inline-block;
        padding: 0.4rem 0.8rem;
        border: 1px solid transparent;
        cursor: pointer;

        &:hover {
            background: #F2F2F2;
            border: 1px solid $color-grey-400;
        }
    }

    .flag-wrapper {
        display: inline-block;
        margin-right: 5px;
        width: 27px;
    }

    @include respond(small-phone) {
        width: 42%;
        padding: 1rem .5rem
    }

    @include respond(phone) {
        width: 33%;
    }

    @include respond(tab-port) {
        width: 23%;
    }

    @include respond(tab-land) {
        width: 18%;
    }

    @include respond(desktop) {
        width: 13%;
    }

    @include respond(big-desktop) {
        width: 13%;
    }
}