.border-1 {
    border: 1px solid;
}

.border-bottom-1 {
    border-bottom: 1px solid;
}


.border-grey-400 {
    border-color: $color-grey-400;
}

.text-grey-700 {
    color: $color-grey-700;
}