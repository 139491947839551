.modal {
    position: fixed;
    top: 0;
    display:flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    z-index: 10;
}

.modal_background {
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 10;
    
}

.modal_wrapper {
    position: absolute;
    align-items: center;
    max-height: 98vh;
    background: white;
    -webkit-box-shadow: 0 1.5rem 3rem rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 1.5rem 3rem rgba(0, 0, 0, 0.15);
    box-shadow: 0 1.5rem 3rem rgba(0, 0, 0, 0.15);
    z-index: 11;

    .close-icon_row {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: flex-end;
        padding: 1rem 2rem;
        border-bottom: 1px solid #E9ECEF;

        .close-icon {
            cursor: pointer;
            display: flex;
        }
    }

    &_content {
        overflow-y: auto;
        max-height: 91vh;

        .modal_image {
            display:flex;
            justify-content: center;
            width: 100%;

            img {
                width:80%;
            }
        }

        .modal_title {
            margin: 2.4rem;
            text-align: center;
            font-weight: bold;
            font-size: 2rem;
        }

        .modal_content_container {
            width: 100%;
            display: flex;
            flex-direction: column;
            padding: 1.8rem 2.4rem;


            .signup_link {
                width: 100%;
                height: 4rem;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 2rem 0;
                padding: 0 5rem;
                background-color: $color-orange-800;
                border: 0;
                font-size: 1.5rem;
                color: #fff;
                text-decoration: none;
                cursor: pointer;  
            }
        
            .signin_text {
                text-align: center;
                font-size: 1.2rem;

                &_link {
                    color: $color-orange-800;
                    cursor: pointer;  
                }
            }
    
            .modal_text {
                text-align: left;
                font-size: 1.25rem;

                .red_dash {
                    color: $color-orange-800;
                    font-weight: bold;
                }
            }

            .modal_text_center_aligned {
                text-align: center;
                font-size: 1.25rem;

                .red_dash {
                    color: $color-orange-800;
                    font-weight: bold;
                }
            }
        }

    }     
}
