.header {
    position: fixed;
    width: 100%;
    height: 6rem;
    background: $color-black;
    z-index: 5;
    border-bottom: 4px solid $color-orange-800;
    top:0;

    &-container {
        padding: 0 2.5rem;
        margin: 0 auto;
        height: 6rem;

        @include respond(small-phone) {
            padding: 0 0.5rem;
        }
    }

    &-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    &-logo {
        display: flex;
        align-items: center;
        text-decoration: none;    

        &-label {
            text-transform: uppercase;
            color: $color-grey-100;
            font-size: 1.3rem;
            line-height:1.6rem;
            align-self: flex-end;
            margin-left:1rem;
            cursor:pointer;

            @include respond(small-phone) {
                display: none;
            }
        }
        
        svg {
            height: 2rem;
        }

        @include respond(small-phone) {
            svg {
                height: 2rem;
            }
        }

        @include respond(phone) {
            svg {
                height: 2rem;
            }
        }

    }

    &-menu {
        display:flex;
        height: 100%;
        gap: 2rem;
        align-items: center;

        &-item {
            display: flex;
            color: $color-grey-100;
            text-decoration: none;
            font-size: 1.3rem;
        }

        &-search {
            display: flex;
            align-items: center;
            cursor:pointer;
        }
    }

}