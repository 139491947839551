.search-bar {
    position:absolute;
    display: none;
    justify-content: center;
    align-items: center;
    background: $color-black;
    width: 100%;
    padding: 5rem;
    z-index: 1;
    visibility: hidden;
    opacity: 0;

    &-visible {
        display: flex;
        visibility: visible;
        opacity: 1;
    }


    &-row {
        display: flex;
        padding: 1rem 0;
        width: 80rem;
        align-items: center;

    }

    &-text {
        height: 2.5rem;
        padding: 2rem 5rem 2rem 5rem;
        background: transparent;
        outline: none;
        border: 0;
        width: 100%;
        font-size: 1.6rem;
        color: $color-grey-100;
        border-bottom: 1px solid $color-grey-medium;
        
        
    }
    &-text:focus {
        outline: none;
        border-bottom: 1px solid $color-orange-800;
    }


    &-icon {
        position: absolute;
        cursor: pointer;
        margin-left: 1rem;

        .search-icon-wrapper {
            color: $color-grey-100;
            font-size: 2.5rem;
        
            &:hover {
                color: $color-orange-800;
            }
        }
    }

    &-icon:hover {
        color: $color-orange-800;
    }
}

