.CDIDetail {
  position: fixed;
  top: 21rem;
  right: 0;
  height: calc(100vh - 21rem);
  width: 25%;
  background-color: $color-grey-100;
  color: $color-black;
  border-left: 0.8rem solid #ECECEC;
  overflow-y: auto;


  @include respond(small-phone) {
    width: 100%
  }

  @include respond(phone) {
    width: 100%
  }

  @include respond(tab-port) {
    width: 100%;
  }

  .close-icon {
    position: absolute;
    top: 3rem;
    right: 3rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: $color-black;
    z-index: 2;
    padding: 0.8rem;
    border: 1px solid transparent;

    &:hover {
      background: #F2F2F2;
      border: 1px solid $color-grey-400;
    }
  }

  &-scene-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
    height: 50%;

    @include respond(desktop) {
      height: 55%
    }

    @include respond(big-desktop) {
      height: 60%;
    }
  }

  &-heading {
    margin-bottom: 1.2rem;
    color: $color-grey-800;
    font-size: 1.6rem;
  }

  &-properties {
    padding: 1rem 2.5rem;
    font-size: 1.4rem;

    &-top {
      display: flex;
      justify-content: space-between;
    }

    &-download {
      display: flex;
      flex-direction: column;

      &-text {
        display: inline-flex;
        margin-top: 1rem;
      }

      &-link {
        color: $color-orange-800;
      }
    }

    &-download-now {
      padding-bottom: 30px;

      a {
        color: $color-orange-800;
      }
    }
  }

  &-description {
    display: flex;
    font-size: 1.4rem;
    color: $color-grey-800;

    &-name {
      font-weight: bold;
      width: 8rem;
    }

    &-value {
      margin-left: 1rem;
      width: 60%;
    }
  }

}