.language-dropdown-option{
    height: 2rem;
    display: flex;
    align-items: center;
    padding: 2rem 0rem;
    color: $color-grey-100;
    font-size: 1.3rem;
    text-decoration: none;
    width: 15rem;
    border-bottom: 1px solid $color-grey-medium-second;
    cursor: pointer;
    z-index: 6;


    &:hover {
        color: $color-orange-800;

    }

    &:last-child {
        border-bottom: 0 !important;
    }
}