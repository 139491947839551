.search-page {
    display:flex;
    flex-direction: column;
    align-items: center;;
    min-height: calc(100vh - 8.8rem);

    &-title {
        position:absolute;
        top: 6rem;
        margin: 1.5rem 0;
        text-transform: uppercase;
        z-index: 4;
        text-align: center;

        span {
            font-weight: 900;
            font-size: 1.8rem;
        }

        @include respond(small-phone) {
            width: 90%;
        }

        @include respond(phone) {
            width: 90%;
        }

        @include respond(desktop) {
            margin: 3rem 0;
        }
        
        @include respond(big-desktop) {
            margin: 3rem 0;
        }
    }

    &-content {
        display: flex;
        width: 100%;
        flex:1;
        min-height: calc(100vh - 8.8rem);

        &-scene {
            width: 50%;
            flex: 1;
    
            .scene-wrapper {
                height: 100%;
                width: 100%;
            }

            @include respond(small-phone) {
                width: 90%;
                margin: 7rem 0;
                height: 40rem;
                flex: auto;
            }

            @include respond(phone) {
                width: 90%;
                margin: 7rem 0;
                height: 40rem;
                flex: auto;
            }

            @include respond(tab-port) {
                width: 70%;
                margin: 7rem 0;
                height: 40rem;
                flex: auto;
                
            }

            @include respond(tab-land) {
                width: 40%;
            }
        }

        &-actions {
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
    
            .content-wrapper { 
                width: 80%;
                display: grid;
                grid-template-columns:  0.1fr 0.4fr 1fr;
                grid-template-rows:  0.2fr 0.8fr 3fr;
                column-gap: 1rem;
                padding: 2.5rem;

                @include respond(small-phone) {
                    width: 90%;
                }

                @include respond(phone) {
                    width: 90%;
                }

                @include respond(tab-port) {
                    width: 70%;
                }

                @include respond(desktop) {
                    width: 80%;
                }
                
                @include respond(big-desktop) {
                    width: 60%;
                }
            }
    
            h2 {
                color: $color-grey-medium;
                font-weight: 600;
                font-size: 2.4rem;
                margin-bottom: 5rem; 
            }
    
            .member-definition {
                &-title {
                    grid-column: 1 / span 3;
                    grid-row: 1;
                }
                &-dropdown {
                    grid-column: 1;
                    grid-row: 2;
                    color: $color-orange-800;
                    cursor: pointer;
                    display: flex;
                    align-self: center;
                    justify-self: center;
                }
    
                &-label {
                    grid-column: 2;
                    grid-row: 2;
                    display: flex;
                    align-self: center;
                    font-weight: 600;
    
                    .member-buttons {
                        display: flex;
                        flex-direction: row;
    
                        &-add,
                        &-remove {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 2rem;
                            height :2.4rem;
                            width: 2.4rem;
                            border: 0.15rem solid #D1D1D1;
                            cursor: pointer;

                            &:hover {
                                background-color: #F5F5F5;
                            }
                        }
    
                        &-add {
                            margin-left: 2rem;
                            background: $color-grey-100;
                            color: $color-orange-800;
                        }
    
                        &-remove {
                            color: $color-orange-800;
                            margin-left: 0.75rem;
                            background: $color-grey-100;
                        }
                    }
                }
    
                &-options {
                    grid-column: 2;
                    grid-row: 3;
    
                    .options-radios {
                        display: flex;
                        flex-wrap: wrap;
                        flex-direction: column;
    
                        .radio-line {
                            display: flex;
                            cursor: pointer;
                            flex: 33.33%;
    
                            &:not(:last-child) {
                                margin-bottom: 0.5rem;

                                @include respond(small-phone) {
                                    margin-bottom: 0;
                                    margin-right: .5rem;
                                }

                                @include respond(phone) {
                                    margin-bottom: 0;
                                    margin-right: .5rem;
                                }
                            }

                            @include respond(small-phone) {
                                font-size: 1.2rem;
                            }

                            @include respond(phone) {
                                font-size: 1.2rem;
                            }
                        }
    
                        .radio-line-checked {
                            color: $color-orange-800;
                            font-weight: 400;
                        }

                        @include respond(small-phone) {
                            flex-direction: row;
                        }

                        @include respond(phone) {
                            flex-direction: row;
                        }
                    }

                    @include respond(small-phone) {
                        display: flex; 
                        grid-column: 3;
                        grid-row: 2;
                        align-items: center;
                        justify-content: flex-end;
                    }

                    @include respond(phone) {
                        display: flex; 
                        grid-column: 3;
                        grid-row: 2;
                        align-items: center;
                        justify-content: flex-end;
                    }
                }
    
                &-properties {
                    grid-column: 3;
                    grid-row: 3;
                    min-width: 29rem;
                    margin-left: 1rem;
    
                    .search-button {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 0;
                        background: $color-orange-800;
                        color: $color-grey-100;
                        margin-top: 2rem;
                        height: 4rem;
                        padding: 0 2rem;
                        cursor: pointer;
                        width: 100%;
                        font-size: 1.4rem;

                        @include respond(big-desktop) {
                            padding: 2rem;
                        }
                    }

                    @include respond(small-phone){
                        grid-column: 2 / span 2;
                        grid-row: 3;
                        min-width: 25rem;
                    }

                    @include respond(phone){
                        grid-column: 2 / span 2;
                        grid-row: 3;
                        min-width: 25rem;
                    }

                    @include respond(big-desktop) {
                        margin-left: 5rem;
                    }
                }

                &-designs {
                    margin-top: 1.5rem;
                    grid-column: 1 / span 3;
                    grid-row: 4;

                    &-title {
                        display: flex;
                        align-items: center;
                        margin-bottom: 1.2rem;
                        
                        span {
                            display:flex;
                        }

                        .info-icon {
                            margin-left: 1rem;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor:pointer;
                            color: $color-orange-800;
                        }

                    }

                    &-items {
                        display: flex;
                        gap: 1.2rem;

                        span {
                            width: 25%;
                        }

                    }

                    @include respond(desktop) {
                        margin-top: 4rem;
                    }

                    @include respond(big-desktop) {
                        margin-top: 4rem;
                    }
                }
            }

            @include respond(small-phone) {
                width: 100%;
            }

            @include respond(phone) {
                width: 100%;
            }

            @include respond(tab-port) {
                width: 100%;
            }

            @include respond(tab-land) {
                width: 60%;
            }
        }

        @include respond(small-phone) {
            flex-direction: column-reverse;
            margin-top: 10rem;
            align-items: center;
            min-height: unset;
        }

        @include respond(phone) {
            flex-direction: column-reverse;
            margin-top: 8rem;
            align-items: center;
            min-height: unset;
        }

        @include respond(tab-port) {
            flex-direction: column-reverse;
            margin-top: 8rem;
            align-items: center;
            min-height: unset;
        }
    }

    @include respond(small-phone) {
        min-height: unset;
    }
   
}
