.message {
    display: flex;
    background: #fdecea;
    align-self: center;
    align-items: center;
    padding: 1.2rem;
    margin: 2rem;
    border-radius: 0.5rem;

    &-detail {
        margin: 6rem 2.5rem 3rem 2.5rem;
        width: calc(100% - 5rem);
    }

    &-search {
        position: absolute;
        top: 7rem;
    }

    &-content {
        display: flex;
        align-items: center;

        &-text {
            color: #915852;
            margin-left: 2rem;
        }
    }

}

