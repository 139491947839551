.radio-container {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-right: 1.7rem;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    .checkmark {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 13px;
        width: 13px;
        background-color: transparent;
        border: 0.125rem solid $color-grey-medium;
        border-radius: 50%;
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the indicator (dot/circle) */
    .checkmark:after {
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: $color-orange-800;
    }

    @include respond(small-phone) {
        margin-right: 0.2rem;
    }

    @include respond(phone) {
        margin-right: 0.2rem;
    }
}
