.nav-item {
    list-style: none;
    display:flex;
    justify-content: flex-end;
    height: 100%;
    z-index: 6;
    
    &-link {
        display: flex;
        align-items: center;
        color: $color-grey-100;
        text-decoration: none;
        font-size: 2.5rem;

        .link-label {
            font-size: 1.3rem;
            cursor: pointer;
            margin: 0 0.5rem;

            

            &-auth {
                @include respond(small-phone) {
                    display: none;
                }
            }
        }        
    }
}