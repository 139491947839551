.connection-summary {
    position:fixed;
    width: 100%;
    height: 15rem;

    background: $color-grey-100;
    border-bottom: 0.8rem solid #ECECEC ;
    display:flex;
    justify-content: center;
    align-items: center;
    z-index: 3;

    .canvas-renderer {
        @include respond(small-phone) {
            width: 30%;
        }

        @include respond(phone) {
            width: 30%;
        }
    }

    &-wrapper {
        display:flex;
        flex-direction: column;
    }

    &-label {
        color: $color-orange-800;
        font-weight: 600;
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-bottom: 1rem;
        font-size: 1.5rem;

        .label-icon-wrapper {
            display: flex;
            align-items: center;
            margin-right: 1rem;
            font-size: 1.5rem;
        }
    }

    &-content {
        display: flex;
        margin-left: 2.5rem;

        @include respond(small-phone) {
            flex-direction: column;
        }

        @include respond(phone) {
            flex-direction: column;
        }

        @include respond(tab-port) {
            flex-direction: column;
        }
    }

    .content-inner {
        display:flex;
    }

    &-member {
        margin-right: 3rem;

        .member-item {
            display: flex;
            justify-content: space-between;
            line-height: 1.6rem;
            font-size: 1.3rem;

            &-name {
                margin-bottom:.5rem;
            }
        }

        @include respond(small-phone) {
            margin-right: 1rem;
        }
    }

    .definition {
        margin-right:5rem;
    }

    &-button {
        margin-left: 2rem;
        align-self: flex-end;
        font-size: 1.3rem;

        @include respond(small-phone) {
            margin-top: 1rem;
        }

        @include respond(phone) {
            margin-top: 1rem;
        }
    }
}