/*
0 - 500px:      Small phone
500 - 600px:    Phone
600 - 900px:    Tablet portrait
900 - 1200px:   Tablet landscape
1200 - 1800px:  Normal desktop
1800px + :      Big desktop
*/
.border-1 {
  border: 1px solid;
}

.border-bottom-1 {
  border-bottom: 1px solid;
}

.border-grey-400 {
  border-color: #E0E0E0;
}

.text-grey-700 {
  color: #424242;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}
@media (min-width: 41.26em) and (max-width: 56.25em) {
  html {
    font-size: 50%;
  }
}
@media (min-width: 56.26em) and (max-width: 75em) {
  html {
    font-size: 56.25%;
  }
}
@media (min-width: 112.5em) {
  html {
    font-size: 75%;
  }
}

body {
  box-sizing: border-box;
}

.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 6rem;
}

body {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.7;
  color: #212529;
}

.canvas-renderer {
  position: relative;
  height: 100%;
}
.canvas-renderer .scene {
  height: 100%;
  width: 100%;
}
.canvas-renderer-detail {
  width: 100%;
}
.canvas-renderer .utilities {
  position: absolute;
  right: 0;
  top: 12%;
}
.canvas-renderer .utilities .navigation {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 0.8rem;
}
.canvas-renderer .utilities .navigation-button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 3rem;
  width: 3rem;
}
.canvas-renderer .utilities .navigation-button:hover {
  background-color: #EBEBEB;
  border: 1px solid #616161;
}
.canvas-renderer .utilities .navigation img {
  background-color: #b3b3b3;
  cursor: pointer;
  height: 1.5em;
  width: 1.5em;
  margin-right: 0.15em;
}
.canvas-renderer .utilities .navigation img:hover {
  background-color: gray;
}
.canvas-renderer .utilities .navigation img:first-of-type {
  border-radius: 0.2em 0 0 0.2em;
}
.canvas-renderer .utilities .navigation img:last-of-type {
  border-radius: 0 0.2em 0.2em 0;
}
.canvas-renderer .utilities .navigation input {
  flex: 1 100%;
  margin: 0;
  padding-top: 1em;
}
@media (min-width: 93.75em) and (max-width: 112.4375em) {
  .canvas-renderer .utilities .navigation {
    gap: 0rem;
  }
}
@media (min-width: 112.5em) {
  .canvas-renderer .utilities .navigation {
    gap: 0rem;
  }
}
@media (max-width: 31.25em) {
  .canvas-renderer .utilities {
    top: -5rem;
  }
}
@media (min-width: 31.26em) and (max-width: 41.25em) {
  .canvas-renderer .utilities {
    top: -5rem;
  }
}
@media (min-width: 41.26em) and (max-width: 56.25em) {
  .canvas-renderer .utilities {
    top: -3rem;
  }
}

.radio-container {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: 1.7rem;
  /* Create the indicator (the dot/circle - hidden when not checked) */
  /* Show the indicator (dot/circle) when checked */
  /* Style the indicator (dot/circle) */
}
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.radio-container .checkmark {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 13px;
  width: 13px;
  background-color: transparent;
  border: 0.125rem solid #868686;
  border-radius: 50%;
}
.radio-container .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.radio-container input:checked ~ .checkmark:after {
  display: block;
}
.radio-container .checkmark:after {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #F36E22;
}
@media (max-width: 31.25em) {
  .radio-container {
    margin-right: 0.2rem;
  }
}
@media (min-width: 31.26em) and (max-width: 41.25em) {
  .radio-container {
    margin-right: 0.2rem;
  }
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  padding: 0 5rem;
  color: #FFFFFF;
  font-size: 1.5rem;
  cursor: pointer;
  border: 0;
  text-decoration: none;
}
.button-text {
  color: #FFFFFF;
  text-decoration: none;
}
.button-primary {
  background-color: #F36E22;
}
.button-secondary {
  color: #000000;
  background-color: #FFFFFF;
  border: 1px solid #D1D1D1;
}
.button-secondary:hover {
  background-color: #F5F5F5;
}
.button-medium {
  height: 3rem;
  padding: 0 2rem;
  font-size: 1.4rem;
}
@media (min-width: 41.26em) and (max-width: 56.25em) {
  .button {
    width: 100%;
  }
}
@media (min-width: 31.26em) and (max-width: 41.25em) {
  .button {
    width: 100%;
  }
}
@media (max-width: 31.25em) {
  .button {
    width: 100%;
  }
}

.nav-item {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  height: 100%;
  z-index: 6;
}
.nav-item-link {
  display: flex;
  align-items: center;
  color: #FFFFFF;
  text-decoration: none;
  font-size: 2.5rem;
}
.nav-item-link .link-label {
  font-size: 1.3rem;
  cursor: pointer;
  margin: 0 0.5rem;
}
@media (max-width: 31.25em) {
  .nav-item-link .link-label-auth {
    display: none;
  }
}

.dropdown {
  position: absolute;
  top: 6rem;
  width: 20rem;
  background-color: #0c0c0c;
  overflow: hidden;
  padding: 1rem 2rem;
  z-index: 500;
}

.menu-item {
  height: 2rem;
  display: flex;
  align-items: center;
  padding: 2rem 0rem;
  color: #FFFFFF;
  font-size: 1.3rem;
  text-decoration: none;
  width: 15rem;
  border-bottom: 1px solid #535353;
  cursor: pointer;
  z-index: 6;
}
.menu-item:hover {
  color: #F36E22;
}
.menu-item:last-child {
  border-bottom: 0 !important;
}

.design-sets-dropdown {
  position: absolute;
  top: 6rem;
  background-color: #0c0c0c;
  overflow: hidden;
  padding: 2rem 2rem;
  z-index: 3;
  width: 20rem;
}

.search-bar {
  position: absolute;
  display: none;
  justify-content: center;
  align-items: center;
  background: #0c0c0c;
  width: 100%;
  padding: 5rem;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
}
.search-bar-visible {
  display: flex;
  visibility: visible;
  opacity: 1;
}
.search-bar-row {
  display: flex;
  padding: 1rem 0;
  width: 80rem;
  align-items: center;
}
.search-bar-text {
  height: 2.5rem;
  padding: 2rem 5rem 2rem 5rem;
  background: transparent;
  outline: none;
  border: 0;
  width: 100%;
  font-size: 1.6rem;
  color: #FFFFFF;
  border-bottom: 1px solid #868686;
}
.search-bar-text:focus {
  outline: none;
  border-bottom: 1px solid #F36E22;
}
.search-bar-icon {
  position: absolute;
  cursor: pointer;
  margin-left: 1rem;
}
.search-bar-icon .search-icon-wrapper {
  color: #FFFFFF;
  font-size: 2.5rem;
}
.search-bar-icon .search-icon-wrapper:hover {
  color: #F36E22;
}
.search-bar-icon:hover {
  color: #F36E22;
}

.member-definition .member-label {
  color: #F36E22;
  font-weight: 600;
}
.member-definition .member-properties-item {
  display: flex;
  align-items: stretch;
  align-self: center;
  justify-content: space-between;
  margin: 0.5rem 0;
  font-size: 1.4rem;
  height: 2.5rem;
}
.member-definition .member-properties-item-input {
  padding: 0.2rem;
  width: 15rem;
  font-size: 1.4rem;
}
.member-definition .member-properties-item-input::-webkit-inner-spin-button,
.member-definition .member-properties-item-input ::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
@media (max-width: 31.25em) {
  .member-definition .member-properties-item-input {
    width: 14rem;
    font-size: 1.2rem;
  }
}
@media (min-width: 31.26em) and (max-width: 41.25em) {
  .member-definition .member-properties-item-input {
    width: 20rem;
  }
}
@media (min-width: 41.26em) and (max-width: 56.25em) {
  .member-definition .member-properties-item-input {
    width: 20rem;
  }
}
@media (min-width: 93.75em) and (max-width: 112.4375em) {
  .member-definition .member-properties-item-input {
    width: 16rem;
  }
}
@media (min-width: 112.5em) {
  .member-definition .member-properties-item-input {
    width: 16rem;
  }
}
@media (max-width: 31.25em) {
  .member-definition .member-properties-item {
    font-size: 1.2rem;
  }
}

.member-select-wrapper {
  display: flex;
  position: relative;
}

.member-select {
  padding: 0 0.3rem;
  width: 14rem;
  font-size: 1.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #767676;
  border-radius: 2px;
  z-index: 1;
  cursor: pointer;
}
.member-select-disabled {
  justify-content: flex-end;
  border: 1px solid #e4e4e4;
}

.member-options {
  position: absolute;
  top: 2.5rem;
  width: 14rem;
  background: #FFFFFF;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  z-index: 2;
  max-height: 16.2rem;
  display: none;
  visibility: hidden;
  opacity: 0;
  flex-direction: column;
}
.member-options-scrollable {
  overflow-y: scroll;
}
.member-options-visible {
  display: flex;
  visibility: visible;
  opacity: 1;
}
.member-options::-webkit-scrollbar {
  width: 0.5rem;
  background-color: #ccc;
}
.member-options::-webkit-scrollbar-thumb {
  width: 0.5rem;
  background: #868686;
}

.member-option {
  padding: 0.4rem;
  cursor: pointer;
  border-bottom: 1px solid #dbdbdb;
}
.member-option:hover {
  background: #F36E22;
  color: #FFFFFF;
}

.language-dropdown {
  position: absolute;
  top: 6rem;
  background-color: #0c0c0c;
  overflow: hidden;
  padding: 1rem 2rem;
  width: 20rem;
  z-index: 6;
}

.language-dropdown-option {
  height: 2rem;
  display: flex;
  align-items: center;
  padding: 2rem 0rem;
  color: #FFFFFF;
  font-size: 1.3rem;
  text-decoration: none;
  width: 15rem;
  border-bottom: 1px solid #535353;
  cursor: pointer;
  z-index: 6;
}
.language-dropdown-option:hover {
  color: #F36E22;
}
.language-dropdown-option:last-child {
  border-bottom: 0 !important;
}

/* Dropdown container */
.design-item-search-dropdown {
  position: relative;
  display: flex;
  width: 15rem;
  border-radius: 2px;
  margin-left: 1rem;
}
@media (max-width: 31.25em) {
  .design-item-search-dropdown {
    width: 14rem;
  }
}
@media (min-width: 31.26em) and (max-width: 41.25em) {
  .design-item-search-dropdown {
    width: 20rem;
  }
}
@media (min-width: 41.26em) and (max-width: 56.25em) {
  .design-item-search-dropdown {
    width: 20rem;
  }
}
@media (min-width: 93.75em) and (max-width: 112.4375em) {
  .design-item-search-dropdown {
    width: 16rem;
  }
}
@media (min-width: 112.5em) {
  .design-item-search-dropdown {
    width: 16rem;
  }
}

/* Dropdown toggle button */
.design-item-search-dropdown-toggle {
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px white;
  cursor: pointer;
  width: 100%;
  text-align: left;
  font-size: 1.4rem;
  padding: 0 0.3rem;
  justify-content: space-between;
}
@media (max-width: 31.25em) {
  .design-item-search-dropdown-toggle {
    font-size: 1.2rem;
  }
}

/* Dropdown menu */
.design-item-search-dropdown-menu {
  position: absolute;
  top: 2.5rem;
  right: 0;
  width: 16rem;
  background: #FFFFFF;
  list-style: none;
  overflow-y: auto;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  z-index: 2;
  flex-direction: column;
  display: flex;
}
.design-item-search-dropdown-menu::-webkit-scrollbar {
  width: 0.5rem;
  background-color: #ccc;
}
.design-item-search-dropdown-menu::-webkit-scrollbar-thumb {
  width: 0.5rem;
  background: #868686;
}
.design-item-search-dropdown-menu .selected {
  background: #F5F5F5;
}
@media (min-width: 93.75em) and (max-width: 112.4375em) {
  .design-item-search-dropdown-menu {
    width: 18rem;
  }
}

/* Dropdown menu item */
.design-item-search-dropdown-menu li {
  display: flex;
  align-items: center;
  padding: 0.4rem;
  cursor: pointer;
}
.design-item-search-dropdown-menu li svg {
  margin-right: 0.8rem;
}
.design-item-search-dropdown-menu li:hover {
  background: #F5F5F5;
}

.toasts-container {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 5;
}

.toast {
  background-color: #444444;
  color: #FFFFFF;
  margin-top: 1rem;
  padding: 1.5rem;
  border-radius: 0.5rem;
  transform: translateY(0);
  transition: 0.4s;
}
.toast-show {
  transform: translateY(0);
}
.toast-error {
  background-color: rgb(220, 0, 0);
}

.modal {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.modal_background {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 10;
}

.modal_wrapper {
  position: absolute;
  align-items: center;
  max-height: 98vh;
  background: white;
  -webkit-box-shadow: 0 1.5rem 3rem rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 1.5rem 3rem rgba(0, 0, 0, 0.15);
  box-shadow: 0 1.5rem 3rem rgba(0, 0, 0, 0.15);
  z-index: 11;
}
.modal_wrapper .close-icon_row {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding: 1rem 2rem;
  border-bottom: 1px solid #E9ECEF;
}
.modal_wrapper .close-icon_row .close-icon {
  cursor: pointer;
  display: flex;
}
.modal_wrapper_content {
  overflow-y: auto;
  max-height: 91vh;
}
.modal_wrapper_content .modal_image {
  display: flex;
  justify-content: center;
  width: 100%;
}
.modal_wrapper_content .modal_image img {
  width: 80%;
}
.modal_wrapper_content .modal_title {
  margin: 2.4rem;
  text-align: center;
  font-weight: bold;
  font-size: 2rem;
}
.modal_wrapper_content .modal_content_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.8rem 2.4rem;
}
.modal_wrapper_content .modal_content_container .signup_link {
  width: 100%;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
  padding: 0 5rem;
  background-color: #F36E22;
  border: 0;
  font-size: 1.5rem;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
.modal_wrapper_content .modal_content_container .signin_text {
  text-align: center;
  font-size: 1.2rem;
}
.modal_wrapper_content .modal_content_container .signin_text_link {
  color: #F36E22;
  cursor: pointer;
}
.modal_wrapper_content .modal_content_container .modal_text {
  text-align: left;
  font-size: 1.25rem;
}
.modal_wrapper_content .modal_content_container .modal_text .red_dash {
  color: #F36E22;
  font-weight: bold;
}
.modal_wrapper_content .modal_content_container .modal_text_center_aligned {
  text-align: center;
  font-size: 1.25rem;
}
.modal_wrapper_content .modal_content_container .modal_text_center_aligned .red_dash {
  color: #F36E22;
  font-weight: bold;
}

.filter-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.1rem 0;
  width: 100%;
  line-height: 1.2;
  padding: 0.5rem 0;
}
.filter-item-icon-with-label {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 65%;
}
.filter-item-icon-with-label .icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.filter-item-icon-with-label svg {
  width: 2rem;
  margin-left: 0.5rem;
}
.filter-item:hover .filter-item-only {
  color: #F36E22;
}
.filter-item-only {
  display: none;
  width: 15%;
  font-size: 1.4rem;
  cursor: pointer;
}
.filter-item-checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15%;
  cursor: pointer;
}
.filter-item:hover {
  background-color: #F2F2F2;
}
.filter-item:hover .filter-item-only {
  display: block;
}

.spinner {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.ribbon {
  width: 30rem;
}

.bottom {
  margin: 2rem auto 10rem;
  width: 7rem;
}

.detail {
  width: 59%;
  margin-left: 16%;
}

.spinner > div {
  width: 1.8rem;
  height: 1.8rem;
  background-color: #333;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.message {
  display: flex;
  background: #fdecea;
  align-self: center;
  align-items: center;
  padding: 1.2rem;
  margin: 2rem;
  border-radius: 0.5rem;
}
.message-detail {
  margin: 6rem 2.5rem 3rem 2.5rem;
  width: calc(100% - 5rem);
}
.message-search {
  position: absolute;
  top: 7rem;
}
.message-content {
  display: flex;
  align-items: center;
}
.message-content-text {
  color: #915852;
  margin-left: 2rem;
}

.ConDesignItem {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  border: 0.2rem solid #FFFFFF;
  padding: 1rem;
  cursor: pointer;
}
.ConDesignItem:hover {
  border: 0.2rem solid #F36E22;
}
.ConDesignItem--image {
  width: 90%;
  border-radius: 1rem;
}

.active {
  border: 0.2rem solid #F36E22;
}

.suggestion-design-card {
  border-radius: 1rem;
  border: 0.2rem solid #FFFFFF;
  padding: 1rem;
  cursor: pointer;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1411764706);
}
.suggestion-design-card:hover {
  border: 0.2rem solid #F36E22;
}
.suggestion-design-card img {
  height: 100%;
  width: 100%;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}
.suggestion-design-card img:hover {
  filter: grayscale(0%);
  -webkit-filter: grayscale(0%);
}

.no-results {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 87%;
  height: 100%;
  margin-left: 13%;
  padding: 1rem;
  height: calc(100vh - 23.8rem);
}
.no-results svg {
  margin-bottom: 2.5rem;
}
.no-results-title {
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.6rem;
  margin-bottom: 2.8rem;
}
.no-results-actions {
  font-size: 1.4rem;
  font-weight: 400;
}
.no-results-actions-link {
  color: #F36E22;
}
@media (max-width: 31.25em) {
  .no-results {
    width: 58%;
    margin-left: 42%;
    text-align: center;
  }
}
@media (min-width: 31.26em) and (max-width: 41.25em) {
  .no-results {
    width: 67%;
    margin-left: 33%;
  }
}
@media (min-width: 41.26em) and (max-width: 56.25em) {
  .no-results {
    width: 77%;
    margin-left: 23%;
  }
}
@media (min-width: 56.26em) and (max-width: 75em) {
  .no-results {
    width: 82%;
    margin-left: 18%;
  }
}
@media (min-width: 93.75em) and (max-width: 112.4375em) {
  .no-results {
    width: 87%;
    margin-left: 13%;
  }
}
@media (min-width: 112.5em) {
  .no-results {
    width: 87%;
    margin-left: 13%;
  }
}

.scroll-to-top-btn {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 5rem;
  right: 5rem;
  height: 4rem;
  width: 4rem;
  border-radius: 100%;
  background: #616161;
  border: 0;
  z-index: 20;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
}
.scroll-to-top-btn-visible {
  visibility: visible;
  opacity: 1;
}

.openInDesktop-dropdown {
  position: relative;
  display: inline-block;
  margin-top: 2.4rem;
  margin-bottom: 0.2rem;
}
.openInDesktop-dropdown .openInDesktop-dropbtn {
  display: flex;
  align-items: stretch;
  background-color: #F36E22;
  color: #FFFFFF;
  font-weight: 600;
  font-size: 1rem;
  height: 3.5rem;
  border: none;
  cursor: pointer;
  outline: none;
}
.openInDesktop-dropdown .openInDesktop-dropbtn .dropbtn-span {
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  padding: 0 1.7rem;
}
.openInDesktop-dropdown .openInDesktop-dropbtn .dropbtn-arrow {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  border-left: 1px solid white;
}
.openInDesktop-dropdown .openInDesktop-dropbtn:hover {
  background-color: #fb923c;
}
.openInDesktop-dropdown .openInDesktop-dropdown-content {
  display: block;
  position: absolute;
  right: 0;
  background-color: white;
  width: 100%;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: unset;
}
.openInDesktop-dropdown .openInDesktop-dropdown-content button {
  color: black;
  padding: 0.6rem 1.6rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  background: transparent;
  width: 100%;
  cursor: pointer;
  text-align: left;
}
.openInDesktop-dropdown .openInDesktop-dropdown-content button svg {
  flex: 1;
  margin-right: 1rem;
}
.openInDesktop-dropdown .openInDesktop-dropdown-content button span {
  flex: 5;
  font-size: 1.4rem;
  margin-right: 1rem;
  white-space: nowrap;
}
.openInDesktop-dropdown .openInDesktop-dropdown-content button:hover {
  background-color: #f1f1f1;
}
@media screen and (max-height: 1068px) and (min-width: 1800px) {
  .openInDesktop-dropdown .openInDesktop-dropdown-content {
    top: -8.8rem;
  }
}
@media screen and (max-height: 803px) and (max-width: 1800px) {
  .openInDesktop-dropdown .openInDesktop-dropdown-content {
    top: -8.8rem;
  }
}

.total-items {
  position: absolute;
  top: 21rem;
  left: 13%;
  padding: 1rem 0;
}
@media (max-width: 31.25em) {
  .total-items {
    left: 40%;
  }
}
@media (min-width: 31.26em) and (max-width: 41.25em) {
  .total-items {
    left: 33%;
  }
}
@media (min-width: 41.26em) and (max-width: 56.25em) {
  .total-items {
    left: 23%;
  }
}
@media (min-width: 56.26em) and (max-width: 75em) {
  .total-items {
    left: 18%;
  }
}
@media (min-width: 93.75em) and (max-width: 112.4375em) {
  .total-items {
    left: 13%;
  }
}
@media (min-width: 112.5em) {
  .total-items {
    left: 13%;
  }
}

.loader-container {
  height: 100%;
  width: 84%;
  margin-left: 16%;
  height: calc(100vh - 23.8rem);
}
.loader-container-detail {
  width: 59% !important;
}
@media (min-width: 56.26em) and (max-width: 75em) {
  .loader-container-detail {
    width: 57% !important;
    margin-left: 18%;
  }
}
@media (min-width: 93.75em) and (max-width: 112.4375em) {
  .loader-container-detail {
    width: 62% !important;
    margin-left: 13%;
  }
}
@media (min-width: 112.5em) {
  .loader-container-detail {
    width: 62% !important;
    margin-left: 13%;
  }
}
@media (max-width: 31.25em) {
  .loader-container {
    width: 58%;
    margin-left: 42%;
  }
}
@media (min-width: 31.26em) and (max-width: 41.25em) {
  .loader-container {
    width: 67%;
    margin-left: 33%;
  }
}
@media (min-width: 41.26em) and (max-width: 56.25em) {
  .loader-container {
    width: 77%;
    margin-left: 23%;
  }
}
@media (min-width: 56.26em) and (max-width: 75em) {
  .loader-container {
    width: 82%;
    margin-left: 18%;
  }
}
@media (min-width: 93.75em) and (max-width: 112.4375em) {
  .loader-container {
    width: 87%;
    margin-left: 13%;
  }
}
@media (min-width: 112.5em) {
  .loader-container {
    width: 87%;
    margin-left: 13%;
  }
}

.con-design-items-page {
  padding-top: 15rem;
}
.con-design-items-page__message {
  width: 87%;
  margin-left: 13%;
  position: relative;
  top: 2rem;
}
.con-design-items-page__container {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 1rem;
  justify-content: center;
  width: 84%;
  margin-left: 16%;
  padding: 3rem 1rem;
  margin-top: 3rem;
}
.con-design-items-page__container__detail {
  grid-template-columns: repeat(5, 1fr);
  width: 59% !important;
}
@media (max-width: 31.25em) {
  .con-design-items-page__container__detail {
    width: 100%;
  }
}
@media (min-width: 31.26em) and (max-width: 41.25em) {
  .con-design-items-page__container__detail {
    width: 100%;
  }
}
@media (min-width: 41.26em) and (max-width: 56.25em) {
  .con-design-items-page__container__detail {
    width: 100%;
  }
}
@media (min-width: 56.26em) and (max-width: 75em) {
  .con-design-items-page__container__detail {
    grid-template-columns: repeat(4, 1fr) !important;
    width: 57% !important;
  }
}
@media (min-width: 93.75em) and (max-width: 112.4375em) {
  .con-design-items-page__container__detail {
    width: 62% !important;
  }
}
@media (min-width: 112.5em) {
  .con-design-items-page__container__detail {
    grid-template-columns: repeat(6, 1fr) !important;
  }
}
@media (max-width: 31.25em) {
  .con-design-items-page__container {
    grid-template-columns: 1fr;
    width: 58%;
    margin-left: 42%;
  }
}
@media (min-width: 31.26em) and (max-width: 41.25em) {
  .con-design-items-page__container {
    grid-template-columns: repeat(2, 1fr);
    width: 67%;
    margin-left: 33%;
  }
}
@media (min-width: 41.26em) and (max-width: 56.25em) {
  .con-design-items-page__container {
    grid-template-columns: repeat(3, 1fr);
    width: 77%;
    margin-left: 23%;
  }
}
@media (min-width: 56.26em) and (max-width: 75em) {
  .con-design-items-page__container {
    grid-template-columns: repeat(5, 1fr);
    width: 82%;
    margin-left: 18%;
  }
}
@media (min-width: 93.75em) and (max-width: 112.4375em) {
  .con-design-items-page__container {
    width: 87%;
    margin-left: 13%;
  }
}
@media (min-width: 112.5em) {
  .con-design-items-page__container {
    grid-template-columns: repeat(8, 1fr);
    width: 87%;
    margin-left: 13%;
  }
}
.con-design-items-page__show-more {
  width: 84%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 4rem 0;
  padding-bottom: 5rem;
  margin-left: 16%;
}
.con-design-items-page__show-more__detail {
  margin-left: 16%;
  width: 59%;
}
@media (min-width: 41.26em) and (max-width: 56.25em) {
  .con-design-items-page__show-more__detail {
    width: 52% !important;
    margin-left: 23% !important;
  }
}
@media (min-width: 56.26em) and (max-width: 75em) {
  .con-design-items-page__show-more__detail {
    width: 57% !important;
    margin-left: 18% !important;
  }
}
@media (min-width: 93.75em) and (max-width: 112.4375em) {
  .con-design-items-page__show-more__detail {
    width: 62% !important;
    margin-left: 13% !important;
  }
}
@media (min-width: 112.5em) {
  .con-design-items-page__show-more__detail {
    width: 62% !important;
    margin-left: 13% !important;
  }
}
@media (max-width: 31.25em) {
  .con-design-items-page__show-more {
    padding: 0 2rem;
    margin-bottom: 8rem;
    width: 58%;
    margin-left: 42%;
  }
}
@media (min-width: 31.26em) and (max-width: 41.25em) {
  .con-design-items-page__show-more {
    padding: 0 2rem;
    margin-bottom: 8rem;
    width: 67%;
    margin-left: 33%;
  }
}
@media (min-width: 41.26em) and (max-width: 56.25em) {
  .con-design-items-page__show-more {
    padding: 0 2rem;
    margin-bottom: 8rem;
    width: 77%;
    margin-left: 23%;
  }
}
@media (min-width: 56.26em) and (max-width: 75em) {
  .con-design-items-page__show-more {
    width: 82%;
    margin-left: 18%;
  }
}
@media (min-width: 93.75em) and (max-width: 112.4375em) {
  .con-design-items-page__show-more {
    width: 87%;
    margin-left: 13%;
  }
}
@media (min-width: 112.5em) {
  .con-design-items-page__show-more {
    width: 87%;
    margin-left: 13%;
  }
}

.search-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 8.8rem);
}
.search-page-title {
  position: absolute;
  top: 6rem;
  margin: 1.5rem 0;
  text-transform: uppercase;
  z-index: 4;
  text-align: center;
}
.search-page-title span {
  font-weight: 900;
  font-size: 1.8rem;
}
@media (max-width: 31.25em) {
  .search-page-title {
    width: 90%;
  }
}
@media (min-width: 31.26em) and (max-width: 41.25em) {
  .search-page-title {
    width: 90%;
  }
}
@media (min-width: 93.75em) and (max-width: 112.4375em) {
  .search-page-title {
    margin: 3rem 0;
  }
}
@media (min-width: 112.5em) {
  .search-page-title {
    margin: 3rem 0;
  }
}
.search-page-content {
  display: flex;
  width: 100%;
  flex: 1;
  min-height: calc(100vh - 8.8rem);
}
.search-page-content-scene {
  width: 50%;
  flex: 1;
}
.search-page-content-scene .scene-wrapper {
  height: 100%;
  width: 100%;
}
@media (max-width: 31.25em) {
  .search-page-content-scene {
    width: 90%;
    margin: 7rem 0;
    height: 40rem;
    flex: auto;
  }
}
@media (min-width: 31.26em) and (max-width: 41.25em) {
  .search-page-content-scene {
    width: 90%;
    margin: 7rem 0;
    height: 40rem;
    flex: auto;
  }
}
@media (min-width: 41.26em) and (max-width: 56.25em) {
  .search-page-content-scene {
    width: 70%;
    margin: 7rem 0;
    height: 40rem;
    flex: auto;
  }
}
@media (min-width: 56.26em) and (max-width: 75em) {
  .search-page-content-scene {
    width: 40%;
  }
}
.search-page-content-actions {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.search-page-content-actions .content-wrapper {
  width: 80%;
  display: grid;
  grid-template-columns: 0.1fr 0.4fr 1fr;
  grid-template-rows: 0.2fr 0.8fr 3fr;
  column-gap: 1rem;
  padding: 2.5rem;
}
@media (max-width: 31.25em) {
  .search-page-content-actions .content-wrapper {
    width: 90%;
  }
}
@media (min-width: 31.26em) and (max-width: 41.25em) {
  .search-page-content-actions .content-wrapper {
    width: 90%;
  }
}
@media (min-width: 41.26em) and (max-width: 56.25em) {
  .search-page-content-actions .content-wrapper {
    width: 70%;
  }
}
@media (min-width: 93.75em) and (max-width: 112.4375em) {
  .search-page-content-actions .content-wrapper {
    width: 80%;
  }
}
@media (min-width: 112.5em) {
  .search-page-content-actions .content-wrapper {
    width: 60%;
  }
}
.search-page-content-actions h2 {
  color: #868686;
  font-weight: 600;
  font-size: 2.4rem;
  margin-bottom: 5rem;
}
.search-page-content-actions .member-definition-title {
  grid-column: 1/span 3;
  grid-row: 1;
}
.search-page-content-actions .member-definition-dropdown {
  grid-column: 1;
  grid-row: 2;
  color: #F36E22;
  cursor: pointer;
  display: flex;
  align-self: center;
  justify-self: center;
}
.search-page-content-actions .member-definition-label {
  grid-column: 2;
  grid-row: 2;
  display: flex;
  align-self: center;
  font-weight: 600;
}
.search-page-content-actions .member-definition-label .member-buttons {
  display: flex;
  flex-direction: row;
}
.search-page-content-actions .member-definition-label .member-buttons-add, .search-page-content-actions .member-definition-label .member-buttons-remove {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  height: 2.4rem;
  width: 2.4rem;
  border: 0.15rem solid #D1D1D1;
  cursor: pointer;
}
.search-page-content-actions .member-definition-label .member-buttons-add:hover, .search-page-content-actions .member-definition-label .member-buttons-remove:hover {
  background-color: #F5F5F5;
}
.search-page-content-actions .member-definition-label .member-buttons-add {
  margin-left: 2rem;
  background: #FFFFFF;
  color: #F36E22;
}
.search-page-content-actions .member-definition-label .member-buttons-remove {
  color: #F36E22;
  margin-left: 0.75rem;
  background: #FFFFFF;
}
.search-page-content-actions .member-definition-options {
  grid-column: 2;
  grid-row: 3;
}
.search-page-content-actions .member-definition-options .options-radios {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.search-page-content-actions .member-definition-options .options-radios .radio-line {
  display: flex;
  cursor: pointer;
  flex: 33.33%;
}
.search-page-content-actions .member-definition-options .options-radios .radio-line:not(:last-child) {
  margin-bottom: 0.5rem;
}
@media (max-width: 31.25em) {
  .search-page-content-actions .member-definition-options .options-radios .radio-line:not(:last-child) {
    margin-bottom: 0;
    margin-right: 0.5rem;
  }
}
@media (min-width: 31.26em) and (max-width: 41.25em) {
  .search-page-content-actions .member-definition-options .options-radios .radio-line:not(:last-child) {
    margin-bottom: 0;
    margin-right: 0.5rem;
  }
}
@media (max-width: 31.25em) {
  .search-page-content-actions .member-definition-options .options-radios .radio-line {
    font-size: 1.2rem;
  }
}
@media (min-width: 31.26em) and (max-width: 41.25em) {
  .search-page-content-actions .member-definition-options .options-radios .radio-line {
    font-size: 1.2rem;
  }
}
.search-page-content-actions .member-definition-options .options-radios .radio-line-checked {
  color: #F36E22;
  font-weight: 400;
}
@media (max-width: 31.25em) {
  .search-page-content-actions .member-definition-options .options-radios {
    flex-direction: row;
  }
}
@media (min-width: 31.26em) and (max-width: 41.25em) {
  .search-page-content-actions .member-definition-options .options-radios {
    flex-direction: row;
  }
}
@media (max-width: 31.25em) {
  .search-page-content-actions .member-definition-options {
    display: flex;
    grid-column: 3;
    grid-row: 2;
    align-items: center;
    justify-content: flex-end;
  }
}
@media (min-width: 31.26em) and (max-width: 41.25em) {
  .search-page-content-actions .member-definition-options {
    display: flex;
    grid-column: 3;
    grid-row: 2;
    align-items: center;
    justify-content: flex-end;
  }
}
.search-page-content-actions .member-definition-properties {
  grid-column: 3;
  grid-row: 3;
  min-width: 29rem;
  margin-left: 1rem;
}
.search-page-content-actions .member-definition-properties .search-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background: #F36E22;
  color: #FFFFFF;
  margin-top: 2rem;
  height: 4rem;
  padding: 0 2rem;
  cursor: pointer;
  width: 100%;
  font-size: 1.4rem;
}
@media (min-width: 112.5em) {
  .search-page-content-actions .member-definition-properties .search-button {
    padding: 2rem;
  }
}
@media (max-width: 31.25em) {
  .search-page-content-actions .member-definition-properties {
    grid-column: 2/span 2;
    grid-row: 3;
    min-width: 25rem;
  }
}
@media (min-width: 31.26em) and (max-width: 41.25em) {
  .search-page-content-actions .member-definition-properties {
    grid-column: 2/span 2;
    grid-row: 3;
    min-width: 25rem;
  }
}
@media (min-width: 112.5em) {
  .search-page-content-actions .member-definition-properties {
    margin-left: 5rem;
  }
}
.search-page-content-actions .member-definition-designs {
  margin-top: 1.5rem;
  grid-column: 1/span 3;
  grid-row: 4;
}
.search-page-content-actions .member-definition-designs-title {
  display: flex;
  align-items: center;
  margin-bottom: 1.2rem;
}
.search-page-content-actions .member-definition-designs-title span {
  display: flex;
}
.search-page-content-actions .member-definition-designs-title .info-icon {
  margin-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #F36E22;
}
.search-page-content-actions .member-definition-designs-items {
  display: flex;
  gap: 1.2rem;
}
.search-page-content-actions .member-definition-designs-items span {
  width: 25%;
}
@media (min-width: 93.75em) and (max-width: 112.4375em) {
  .search-page-content-actions .member-definition-designs {
    margin-top: 4rem;
  }
}
@media (min-width: 112.5em) {
  .search-page-content-actions .member-definition-designs {
    margin-top: 4rem;
  }
}
@media (max-width: 31.25em) {
  .search-page-content-actions {
    width: 100%;
  }
}
@media (min-width: 31.26em) and (max-width: 41.25em) {
  .search-page-content-actions {
    width: 100%;
  }
}
@media (min-width: 41.26em) and (max-width: 56.25em) {
  .search-page-content-actions {
    width: 100%;
  }
}
@media (min-width: 56.26em) and (max-width: 75em) {
  .search-page-content-actions {
    width: 60%;
  }
}
@media (max-width: 31.25em) {
  .search-page-content {
    flex-direction: column-reverse;
    margin-top: 10rem;
    align-items: center;
    min-height: unset;
  }
}
@media (min-width: 31.26em) and (max-width: 41.25em) {
  .search-page-content {
    flex-direction: column-reverse;
    margin-top: 8rem;
    align-items: center;
    min-height: unset;
  }
}
@media (min-width: 41.26em) and (max-width: 56.25em) {
  .search-page-content {
    flex-direction: column-reverse;
    margin-top: 8rem;
    align-items: center;
    min-height: unset;
  }
}
@media (max-width: 31.25em) {
  .search-page {
    min-height: unset;
  }
}

.header {
  position: fixed;
  width: 100%;
  height: 6rem;
  background: #0c0c0c;
  z-index: 5;
  border-bottom: 4px solid #F36E22;
  top: 0;
}
.header-container {
  padding: 0 2.5rem;
  margin: 0 auto;
  height: 6rem;
}
@media (max-width: 31.25em) {
  .header-container {
    padding: 0 0.5rem;
  }
}
.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}
.header-logo {
  display: flex;
  align-items: center;
  text-decoration: none;
}
.header-logo-label {
  text-transform: uppercase;
  color: #FFFFFF;
  font-size: 1.3rem;
  line-height: 1.6rem;
  align-self: flex-end;
  margin-left: 1rem;
  cursor: pointer;
}
@media (max-width: 31.25em) {
  .header-logo-label {
    display: none;
  }
}
.header-logo svg {
  height: 2rem;
}
@media (max-width: 31.25em) {
  .header-logo svg {
    height: 2rem;
  }
}
@media (min-width: 31.26em) and (max-width: 41.25em) {
  .header-logo svg {
    height: 2rem;
  }
}
.header-menu {
  display: flex;
  height: 100%;
  gap: 2rem;
  align-items: center;
}
.header-menu-item {
  display: flex;
  color: #FFFFFF;
  text-decoration: none;
  font-size: 1.3rem;
}
.header-menu-search {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.filters {
  position: fixed;
  top: 21rem;
  left: 0;
  height: 100%;
  width: 16%;
  padding: 1rem 2rem;
}
.filters-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
}
.filters-group-title {
  margin-bottom: 1rem;
}
.filters-clear {
  display: inline-block;
  padding: 0.4rem 0.8rem;
  border: 1px solid transparent;
  cursor: pointer;
}
.filters-clear:hover {
  background: #F2F2F2;
  border: 1px solid #E0E0E0;
}
.filters .flag-wrapper {
  display: inline-block;
  margin-right: 5px;
  width: 27px;
}
@media (max-width: 31.25em) {
  .filters {
    width: 42%;
    padding: 1rem 0.5rem;
  }
}
@media (min-width: 31.26em) and (max-width: 41.25em) {
  .filters {
    width: 33%;
  }
}
@media (min-width: 41.26em) and (max-width: 56.25em) {
  .filters {
    width: 23%;
  }
}
@media (min-width: 56.26em) and (max-width: 75em) {
  .filters {
    width: 18%;
  }
}
@media (min-width: 93.75em) and (max-width: 112.4375em) {
  .filters {
    width: 13%;
  }
}
@media (min-width: 112.5em) {
  .filters {
    width: 13%;
  }
}

.connection-summary {
  position: fixed;
  width: 100%;
  height: 15rem;
  background: #FFFFFF;
  border-bottom: 0.8rem solid #ECECEC;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}
@media (max-width: 31.25em) {
  .connection-summary .canvas-renderer {
    width: 30%;
  }
}
@media (min-width: 31.26em) and (max-width: 41.25em) {
  .connection-summary .canvas-renderer {
    width: 30%;
  }
}
.connection-summary-wrapper {
  display: flex;
  flex-direction: column;
}
.connection-summary-label {
  color: #F36E22;
  font-weight: 600;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}
.connection-summary-label .label-icon-wrapper {
  display: flex;
  align-items: center;
  margin-right: 1rem;
  font-size: 1.5rem;
}
.connection-summary-content {
  display: flex;
  margin-left: 2.5rem;
}
@media (max-width: 31.25em) {
  .connection-summary-content {
    flex-direction: column;
  }
}
@media (min-width: 31.26em) and (max-width: 41.25em) {
  .connection-summary-content {
    flex-direction: column;
  }
}
@media (min-width: 41.26em) and (max-width: 56.25em) {
  .connection-summary-content {
    flex-direction: column;
  }
}
.connection-summary .content-inner {
  display: flex;
}
.connection-summary-member {
  margin-right: 3rem;
}
.connection-summary-member .member-item {
  display: flex;
  justify-content: space-between;
  line-height: 1.6rem;
  font-size: 1.3rem;
}
.connection-summary-member .member-item-name {
  margin-bottom: 0.5rem;
}
@media (max-width: 31.25em) {
  .connection-summary-member {
    margin-right: 1rem;
  }
}
.connection-summary .definition {
  margin-right: 5rem;
}
.connection-summary-button {
  margin-left: 2rem;
  align-self: flex-end;
  font-size: 1.3rem;
}
@media (max-width: 31.25em) {
  .connection-summary-button {
    margin-top: 1rem;
  }
}
@media (min-width: 31.26em) and (max-width: 41.25em) {
  .connection-summary-button {
    margin-top: 1rem;
  }
}

.CDIDetail {
  position: fixed;
  top: 21rem;
  right: 0;
  height: calc(100vh - 21rem);
  width: 25%;
  background-color: #FFFFFF;
  color: #0c0c0c;
  border-left: 0.8rem solid #ECECEC;
  overflow-y: auto;
}
@media (max-width: 31.25em) {
  .CDIDetail {
    width: 100%;
  }
}
@media (min-width: 31.26em) and (max-width: 41.25em) {
  .CDIDetail {
    width: 100%;
  }
}
@media (min-width: 41.26em) and (max-width: 56.25em) {
  .CDIDetail {
    width: 100%;
  }
}
.CDIDetail .close-icon {
  position: absolute;
  top: 3rem;
  right: 3rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #0c0c0c;
  z-index: 2;
  padding: 0.8rem;
  border: 1px solid transparent;
}
.CDIDetail .close-icon:hover {
  background: #F2F2F2;
  border: 1px solid #E0E0E0;
}
.CDIDetail-scene-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  height: 50%;
}
@media (min-width: 93.75em) and (max-width: 112.4375em) {
  .CDIDetail-scene-wrapper {
    height: 55%;
  }
}
@media (min-width: 112.5em) {
  .CDIDetail-scene-wrapper {
    height: 60%;
  }
}
.CDIDetail-heading {
  margin-bottom: 1.2rem;
  color: #242424;
  font-size: 1.6rem;
}
.CDIDetail-properties {
  padding: 1rem 2.5rem;
  font-size: 1.4rem;
}
.CDIDetail-properties-top {
  display: flex;
  justify-content: space-between;
}
.CDIDetail-properties-download {
  display: flex;
  flex-direction: column;
}
.CDIDetail-properties-download-text {
  display: inline-flex;
  margin-top: 1rem;
}
.CDIDetail-properties-download-link {
  color: #F36E22;
}
.CDIDetail-properties-download-now {
  padding-bottom: 30px;
}
.CDIDetail-properties-download-now a {
  color: #F36E22;
}
.CDIDetail-description {
  display: flex;
  font-size: 1.4rem;
  color: #242424;
}
.CDIDetail-description-name {
  font-weight: bold;
  width: 8rem;
}
.CDIDetail-description-value {
  margin-left: 1rem;
  width: 60%;
}

.footer {
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 2.8rem;
  background-color: #0c0c0c;
  z-index: 2;
}
.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 2.5rem;
}
.footer-copyright {
  display: flex;
  align-items: center;
  color: #FFFFFF;
  font-size: 1.2rem;
}
.footer-links-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer-link {
  color: #FFFFFF;
  font-size: 1.2rem;
}
.footer-link:not(:last-child) {
  margin-right: 1rem;
}

