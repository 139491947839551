.no-results {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 87%; 
    height: 100%;
    margin-left: 13%;
    padding: 1rem;
    height: calc(100vh - 23.8rem);

    svg {
        margin-bottom: 2.5rem;
    }

    &-title {
        font-size: 2rem;
        font-weight: 700;
        line-height: 2.6rem;
        margin-bottom: 2.8rem
    }

    &-actions {
        font-size: 1.4rem;
        font-weight: 400;

        &-link {
            color: $color-orange-800
        }
    }

    @include respond(small-phone) {
        width: 58%;
        margin-left: 42%;
        text-align: center;
    }

    @include respond(phone) { 
        width: 67%;
        margin-left: 33%;
    }

    @include respond(tab-port) {
        width: 77%;
        margin-left: 23%;
    }

    @include respond(tab-land) {
        width: 82%;
        margin-left: 18%;
    }

    @include respond(desktop) {
        width: 87%;
        margin-left: 13%;
    }

    @include respond(big-desktop) {
        width: 87%;
        margin-left: 13%;
    }
}