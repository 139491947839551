.suggestion-design-card {
    border-radius: 1rem;
    border: 0.2rem solid $color-grey-100;
    padding: 1rem;
    cursor: pointer;

    box-shadow: 0px 2px 4px 0px #00000024;


    &:hover {
        border: 0.2rem solid $color-orange-800;
    }

    img {
        height: 100%;
        width: 100%;
        filter: grayscale(100%);
        -webkit-filter: grayscale(100%);

        &:hover {
            filter: grayscale(0%);
            -webkit-filter: grayscale(0%);
        }
    }
}