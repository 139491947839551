.member-select-wrapper {
    display:flex;
    position:relative;
}

.member-select {
    padding: 0 0.3rem;
    width: 14rem;
    font-size: 1.4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #767676;
    border-radius:2px; 
    z-index:1;
    cursor: pointer;

    &-disabled {
        justify-content: flex-end;
        border: 1px solid #e4e4e4;
    }
}

