$color-grey-dark: #212529;
$color-grey-medium: #868686;
$color-grey-medium-second: #535353;
$color-grey-light: #f7f7f7;
$color-grey-light-second: #dbdbdb;

$color-grey-100: #FFFFFF;
$color-grey-150: #FAFAFA;
$color-grey-200: #F5F5F5;
$color-grey-250: #F0F0F0;
$color-grey-300: #EBEBEB;
$color-grey-400: #E0E0E0;
$color-grey-500: #D1D1D1;
$color-grey-550: #BDBDBD;
$color-grey-580: #707070;
$color-grey-600: #616161;
$color-grey-700: #424242;
$color-grey-800: #242424;
$color-grey-900: #000000;

$color-orange-800: #F36E22;
$color-orange-900: #D6550C;

$color-static-grey-400: #333333;


$color-black: #0c0c0c;

$button-background-color: #b3b3b3;
$primary-background-color1: rgb(128, 128, 128);
$primary-background-color2: rgb(242, 242, 242);
$primary-background-color3: white;

$border-radius: 2px;
$button-size: 1.5em;