.scroll-to-top-btn {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 5rem;
    right: 5rem;
    height: 4rem;
    width: 4rem;
    border-radius: 100%;
    background: $color-grey-600;
    border: 0;
    z-index: 20;
    transition: .3s;
    opacity: 0;
    visibility: hidden;
    cursor: pointer;

    &-visible {
        visibility: visible;
        opacity: 1;
    }
}