.filter-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: .1rem 0;
    width: 100%;
    line-height: 1.2;
    padding: .5rem 0;

    &-icon-with-label {
        display: flex;
        align-items: center;
        gap: 1rem;
        width: 65%;

        .icon-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    
        svg {
            width: 2rem;
            margin-left: 0.5rem;
        }
    }

    &:hover &-only {
        color: $color-orange-800;
    }

    &-only {
        display:none;
        width: 15%;
        font-size: 1.4rem;
        cursor: pointer;
    }

    &-checkbox {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 15%;
        cursor: pointer;
    }

    &:hover {
        background-color: #F2F2F2;
    }

    &:hover &-only {
        display: block;
    }
}