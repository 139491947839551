.canvas-renderer {
    position: relative;
    height: 100%;

    .scene {
        height: 100%;
		width: 100%;
    }

	&-detail {
		width: 100%;
	}

    .utilities {
		position: absolute;
		right: 0;
		top: 12%;

		.navigation {
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-end;
			gap: 0.8rem;

			&-button {
				display:flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;
				height: 3rem;
				width: 3rem;

				&:hover {
					background-color: $color-grey-300;
					border: 1px solid $color-grey-600;
				}
			}

			img {
				background-color: $button-background-color;
				cursor: pointer;
				height: $button-size;
				width: $button-size;
				margin-right: .15em;

				$radius: .2em;

				&:hover {
					background-color: darken($button-background-color, 20%);
				}

				&:first-of-type {
					border-radius: $radius 0 0 $radius;
				}

				&:last-of-type {
					border-radius: 0 $radius $radius 0;
				}
			}

			input {
				flex: 1 100%;
				margin: 0;
				padding-top: 1em;
			}

			@include respond(desktop) {
				gap: 0rem;
			}
			@include respond(big-desktop) {
				gap: 0rem;
			}
		}

		@include respond(small-phone) {
			top: -5rem;
		}

		@include respond(phone) {
			top: -5rem;
		}

		@include respond(tab-port) {
			top: -3rem;
		}
	}
}