/* Dropdown container */
.design-item-search-dropdown {
  position: relative;
  display: flex;
  width: 15rem;
  border-radius: 2px;
  margin-left: 1rem;

  @include respond(small-phone) {
    width: 14rem;
  }

  @include respond(phone) {
    width: 20rem;
  }

  @include respond(tab-port) {
    width: 20rem;
  }

  @include respond (desktop) {
    width: 16rem;
  }
  
  @include respond (big-desktop) {
    width: 16rem;
  }
}
  
  /* Dropdown toggle button */
.design-item-search-dropdown-toggle {
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px white;
  cursor: pointer;
  width: 100%;
  text-align: left;
  font-size: 1.4rem;
  padding: 0 0.3rem;
  justify-content: space-between;

  @include respond(small-phone) {
    font-size: 1.2rem;
  }
}
  
/* Dropdown menu */
.design-item-search-dropdown-menu {
  position: absolute;
  top: 2.5rem;
  right: 0;
  width: 16rem;
  background: $color-grey-100;
  list-style: none;
  overflow-y: auto;  
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  z-index: 2;
  flex-direction: column;
  display: flex;

  &::-webkit-scrollbar {
      width: .5rem;
      background-color: #ccc;
  }
  &::-webkit-scrollbar-thumb {
      width: .5rem;
      background: $color-grey-medium;
  }

  .selected {
    background: $color-grey-200;
  }

  @include respond(desktop) {
    width: 18rem;
  }
}
  
/* Dropdown menu item */
.design-item-search-dropdown-menu li {
  display: flex;
  align-items: center;
  padding: 0.4rem;
  cursor: pointer;

  svg {
    margin-right: 0.8rem;
  }

  &:hover {
      background: $color-grey-200;
  }  
}