.member-definition {
    .member-label {
        color: $color-orange-800;
        font-weight: 600;
    }

    .member-properties {
        &-item {
            display: flex;
            align-items: stretch;
            align-self: center;
            justify-content: space-between;
            margin: 0.5rem 0;
            font-size: 1.4rem;
            height: 2.5rem;

            &-input {
                padding: 0.2rem;
                width: 15rem;
                font-size: 1.4rem;
                

                &::-webkit-inner-spin-button,
                ::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                    appearance: none;
                    margin: 0;
                }

                @include respond(small-phone) {
                    width: 14rem;
                    font-size: 1.2rem;
                }
        
                @include respond(phone) {
                    width: 20rem;
                }

                @include respond(tab-port) {
                    width: 20rem;
                }

                @include respond(desktop) {
                    width: 16rem;
                }

                @include respond (big-desktop) {
                    width: 16rem;
                  }
            }

            @include respond(small-phone) {
                font-size: 1.2rem;
            }
        }
    }
}
