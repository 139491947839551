.ConDesignItem {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    border: 0.2rem solid $color-grey-100;
    padding: 1rem;
    cursor: pointer;

    &:hover {
        border: 0.2rem solid $color-orange-800;
    }

    &--image {
        width: 90%;
        border-radius: 1rem;
    }

}

.active {
    border: 0.2rem solid $color-orange-800;
}